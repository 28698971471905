import {
  ArrowLeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  Input,
  Popconfirm,
  Select,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import { GridMinMax } from "../../../assets/styles/GlobalStyle";
import Loader from "../../../components/Loader";
import UploadFile from "../../../components/UploadFile";
import {
  AdminProductDetail,
  EnabledLanguage,
  Risks,
} from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import Editor from "../../admin_components/admin_editor/Editor";
const { Text } = Typography;

type ProductTranslationFIelds = {
  title: string;
  description: string;
  importantConditions: string;
};

const Admin_products_edit = () => {
  const { productType: productTypeStr = "" } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  const [productState, setProductState] = useState<Partial<AdminProductDetail>>(
    {}
  );

  // existing product
  const {
    data: existingProduct,
    isLoading: isExistingProductLoading,
    refetch: isExistingProductRefetch,
  } = api.useGetAdminProductDetailByTypeQuery(productTypeStr);

  // get all travel risks
  const { data: travelRisks, isLoading: isTravelRisksLoading } =
    api.useGetAdminTravelRiskListQuery(undefined);

  // get all welcomer risks
  const { data: welcomerRisks, isLoading: isWelcomerRisksLoading } =
    api.useGetAdminWelcomerRiskListQuery(undefined);

  // create
  const [storePageInfo, { isLoading: isStoreProductPageInfoLoadign }] =
    api.useStoreAdminProductDetailMutation();

  // update
  const [updatePageInfo, { isLoading: isUpdateProductPageInfoLoadign }] =
    api.useUpdateAdminProductDetailMutation();

  // remove
  const [removePageInfo, { isLoading: isRemoveProductPageInfoLoadign }] =
    api.useRemoveAdminProductDetailMutation();

  useEffect(() => {
    if (existingProduct) {
      setProductState(existingProduct);
    }
  }, [existingProduct]);

  // add product fields
  const setProductFields = (
    field: keyof Partial<AdminProductDetail>,
    value: any
  ) => {
    setProductState({
      ...productState,
      [field]: value,
    });
  };

  // add risk fields
  const setRisksFields = (field: keyof Partial<Risks>, value: any) => {
    setProductState({
      ...productState,
      risks: {
        ...productState.risks,
        [field]: value,
      },
    });
  };

  // add product translation fields
  const setProductTranslationFIelds = (
    field: keyof ProductTranslationFIelds,
    language: EnabledLanguage,
    value: any
  ) => {
    setProductState({
      ...productState,
      translations: {
        ...productState?.translations,
        [language]: {
          ...productState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  // add comapny translation fields
  const setCompanyTranslationFIelds = (
    field: keyof Partial<ProductTranslationFIelds>,
    language: EnabledLanguage,
    value: any
  ) => {
    setProductState({
      ...productState,
      companyProfile: {
        ...productState?.companyProfile,
        [language]: {
          ...productState?.companyProfile?.[language],
          [field]: value,
        },
      },
    });
  };

  // add covereds translation fields
  const setCoveredsTranslationFIelds = (
    field: keyof { coveredTitle: string; notCoveredTitle: string },
    language: EnabledLanguage,
    value: any
  ) => {
    setProductState({
      ...productState,
      risks: {
        ...productState?.risks,
        translations: {
          ...productState?.risks?.translations,
          [language]: {
            ...productState?.risks?.translations?.[language],
            [field]: value,
          },
        },
      },
    });
  };

  // store product page info
  const _storeProductPageInfo = async () => {
    try {
      await storePageInfo(productState).unwrap();
      // refetch
      isExistingProductRefetch();
      toast.success(`${t("createdSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // store product page info
  const _updateProductPageInfo = async () => {
    try {
      await updatePageInfo(productState).unwrap();
      // refetch
      isExistingProductRefetch();
      toast.success(`${t("updatedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // store product page info
  const _removeProductPageInfo = async () => {
    if (productState && productState.id) {
      try {
        await removePageInfo(productState.id).unwrap();
        // refetch
        isExistingProductRefetch();
        toast.success(`${t("deletedSuccessfully")}`);
        navigate("/edit-product-detail");
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };
  const risks =
    productState?.type === "travel"
      ? travelRisks
      : productState?.type === "welcomer"
      ? welcomerRisks
      : undefined;

  const MainLoading =
    isExistingProductLoading ||
    isTravelRisksLoading ||
    isStoreProductPageInfoLoadign ||
    isUpdateProductPageInfoLoadign ||
    isRemoveProductPageInfoLoadign ||
    isWelcomerRisksLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between" align="center">
        <Button
          size="large"
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <Title level={3}>
          {productTypeStr === "store" ? t("create") : t("update")}
        </Title>

        <AdminLangSwicher />
      </Flex>

      <Divider />

      <Flex vertical gap={20}>
        <GridMinMax>
          <Space direction="vertical">
            <Text>{t("title")}</Text>
            <Input
              size="large"
              placeholder={t("title")}
              value={productState?.translations?.[field_Language]?.title || ""}
              onChange={(event) =>
                setProductTranslationFIelds(
                  "title",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("product")}</Text>
            <Select
              style={{
                width: "100%",
              }}
              allowClear
              placeholder={t("product")}
              size="large"
              disabled={
                productTypeStr !== "store" && productState?.type ? true : false
              }
              value={productState?.type}
              onChange={(value) => setProductFields("type", value)}
              options={[
                ...["travel", "tpl", "welcomer", "pet"].map((item) => ({
                  value: item,
                  label: item?.toUpperCase(),
                })),
              ]}
            />
          </Space>
        </GridMinMax>

        <GridMinMax>
          <Space direction="vertical">
            <Text>{`${t(
              "companySectionTitle"
            )} / ${field_Language?.toUpperCase()}`}</Text>
            <TextArea
              size="large"
              placeholder={`${t(
                "companySectionTitle"
              )} / ${field_Language?.toUpperCase()}`}
              autoSize={{ minRows: 2 }}
              value={
                productState?.companyProfile?.[field_Language]?.title || ""
              }
              onChange={(event) =>
                setCompanyTranslationFIelds(
                  "title",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{`${t(
              "companySectionDesription"
            )} / ${field_Language?.toUpperCase()}`}</Text>
            <TextArea
              size="large"
              placeholder={`${t(
                "companySectionDesription"
              )} / ${field_Language?.toUpperCase()}`}
              autoSize={{ minRows: 2 }}
              value={
                productState?.companyProfile?.[field_Language]?.description ||
                ""
              }
              onChange={(event) =>
                setCompanyTranslationFIelds(
                  "description",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        </GridMinMax>

        <UploadFile
          label={t("pageCoverImage")}
          defaultFileName={productState?.photoUrl || ""}
          onChange={(props) => {
            if (props && props.url && props.key) {
              setProductState({
                ...productState,
                photoUrl: props.url,
                fileKey: props.key,
              });
            }
          }}
        />
        <GridMinMax>
          <Flex vertical gap={10}>
            <Text>
              {t("description")} / {field_Language?.toUpperCase()}
            </Text>
            <Editor
              value={productState?.translations?.[field_Language]?.description}
              onChange={(value) =>
                setProductTranslationFIelds(
                  "description",
                  field_Language,
                  value
                )
              }
            />
          </Flex>

          <Flex vertical gap={10}>
            <Text>
              {t("importantTerms")} / {field_Language?.toUpperCase()}
            </Text>
            <Editor
              value={
                productState?.translations?.[field_Language]
                  ?.importantConditions
              }
              onChange={(value) =>
                setProductTranslationFIelds(
                  "importantConditions",
                  field_Language,
                  value
                )
              }
            />
          </Flex>
        </GridMinMax>
      </Flex>

      <Divider />

      {(productState.type === "travel" || productState.type === "welcomer") && (
        <>
          <Collapse
            size="large"
            style={{ width: "100%" }}
            items={[
              {
                key: "1",
                label: t("chooseWhatIsCovered"),
                children: (
                  <Flex vertical gap={20}>
                    <Space direction="vertical">
                      <Text>{`${t(
                        "title"
                      )} / ${field_Language?.toUpperCase()}`}</Text>
                      <Input
                        size="large"
                        placeholder={`${t(
                          "title"
                        )} / ${field_Language?.toUpperCase()}`}
                        value={
                          productState.risks?.translations?.[field_Language]
                            ?.coveredTitle || ""
                        }
                        onChange={(event) =>
                          setCoveredsTranslationFIelds(
                            "coveredTitle",
                            field_Language,
                            event?.target?.value
                          )
                        }
                      />
                    </Space>

                    <Divider />

                    <GridMinMax>
                      {risks &&
                        risks.map((item, index) => {
                          const existingRisk =
                            productState.risks?.covered?.find(
                              (x) => x.travelRiskId === item.id
                            );

                          return (
                            <Card
                              key={index}
                              style={{
                                borderColor: existingRisk
                                  ? Colors.blue
                                  : Colors.nevadaGrey,
                              }}
                              cover={
                                <img
                                  src={item.icon}
                                  alt={item.icon}
                                  style={{
                                    height: 200,
                                    objectFit: "contain",
                                    paddingTop: 20,
                                  }}
                                />
                              }
                            >
                              <Divider orientation="right">
                                <Checkbox
                                  checked={existingRisk ? true : false}
                                  onChange={(event) => {
                                    if (
                                      productState?.risks?.covered?.find(
                                        (x) => x.travelRiskId === item.id
                                      )
                                    ) {
                                      // remove risk from state
                                      setProductState({
                                        ...productState,
                                        risks: {
                                          ...productState.risks,
                                          covered: [
                                            ...productState.risks.covered.filter(
                                              (x) => x.travelRiskId !== item.id
                                            ),
                                          ],
                                        },
                                      });
                                    } else {
                                      setProductState({
                                        ...productState,
                                        risks: {
                                          ...productState.risks,
                                          covered: [
                                            ...(productState?.risks?.covered ||
                                              []),
                                            {
                                              travelRiskId: item.id,
                                              icon: item.icon,
                                              translations: {},
                                            },
                                          ],
                                        },
                                      });
                                    }
                                  }}
                                />
                              </Divider>

                              <Flex vertical gap={20}>
                                <Space
                                  direction="vertical"
                                  style={{ width: "100%" }}
                                >
                                  <Text>{`${t(
                                    "title"
                                  )} / ${field_Language?.toUpperCase()}`}</Text>
                                  <Input
                                    size="large"
                                    placeholder={`${t(
                                      "title"
                                    )} / ${field_Language?.toUpperCase()}`}
                                    disabled={
                                      productState.risks?.covered?.find(
                                        (x) => x.travelRiskId === item.id
                                      )
                                        ? false
                                        : true
                                    }
                                    value={
                                      productState.risks?.covered?.find(
                                        (x) => x.travelRiskId === item.id
                                      )?.translations?.[field_Language]
                                        ?.title || ""
                                    }
                                    onChange={(event) => {
                                      const editedCovered =
                                        productState.risks?.covered?.map((c) =>
                                          c.travelRiskId === item.id
                                            ? {
                                                ...c,
                                                translations: {
                                                  ...c.translations,
                                                  [field_Language]: {
                                                    ...c.translations?.[
                                                      field_Language
                                                    ],
                                                    title: event?.target?.value,
                                                  },
                                                },
                                              }
                                            : c
                                        );

                                      setRisksFields("covered", editedCovered);
                                    }}
                                  />
                                </Space>

                                <Space
                                  direction="vertical"
                                  style={{ width: "100%" }}
                                >
                                  <Text>
                                    {t("description") +
                                      " / " +
                                      field_Language?.toUpperCase()}
                                  </Text>
                                  <TextArea
                                    size="large"
                                    placeholder={
                                      t("description") +
                                      " / " +
                                      field_Language?.toUpperCase()
                                    }
                                    disabled={
                                      productState.risks?.covered?.find(
                                        (x) => x.travelRiskId === item.id
                                      )
                                        ? false
                                        : true
                                    }
                                    autoSize={{ minRows: 2 }}
                                    value={
                                      productState.risks?.covered?.find(
                                        (x) => x.travelRiskId === item.id
                                      )?.translations?.[field_Language]
                                        ?.description || ""
                                    }
                                    onChange={(event) => {
                                      const editedCovered =
                                        productState.risks?.covered?.map((c) =>
                                          c.travelRiskId === item.id
                                            ? {
                                                ...c,
                                                translations: {
                                                  ...c.translations,
                                                  [field_Language]: {
                                                    ...c.translations?.[
                                                      field_Language
                                                    ],
                                                    description:
                                                      event?.target?.value,
                                                  },
                                                },
                                              }
                                            : c
                                        );

                                      setRisksFields("covered", editedCovered);
                                    }}
                                  />
                                </Space>
                              </Flex>
                            </Card>
                          );
                        })}
                    </GridMinMax>
                  </Flex>
                ),
              },
            ]}
          />
        </>
      )}

      <Flex vertical gap={20}>
        {(productState.type === "tpl" || productState.type === "pet") && (
          <Collapse
            size="large"
            style={{ width: "100%" }}
            items={[
              {
                key: "2",
                label: t("addWhatIsCovered"),
                children: (
                  <Flex vertical gap={20}>
                    <Space direction="vertical">
                      <Text>{t("title")}</Text>
                      <Input
                        size="large"
                        placeholder={t("title")}
                        value={
                          productState.risks?.translations?.[field_Language]
                            ?.coveredTitle || ""
                        }
                        onChange={(event) =>
                          setCoveredsTranslationFIelds(
                            "coveredTitle",
                            field_Language,
                            event?.target?.value
                          )
                        }
                      />
                    </Space>

                    <Divider orientation="left">
                      <Button
                        style={{ width: "fit-content" }}
                        size="large"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          setProductState({
                            ...productState,
                            risks: {
                              ...productState.risks,
                              covered: [
                                ...(productState.risks?.covered || []),
                                {
                                  travelRiskId:
                                    1 *
                                    ((productState.risks?.covered?.length ||
                                      0) +
                                      1),
                                  translations: {
                                    [field_Language]: {
                                      description: "",
                                    },
                                  },
                                },
                              ],
                            },
                          });
                        }}
                      >
                        {t("add")}
                      </Button>
                    </Divider>

                    <GridMinMax align="stretch">
                      {productState.risks?.covered?.map(
                        (coveredItem, coveredIndex) => {
                          return (
                            <Card key={coveredIndex}>
                              <Button
                                type="link"
                                style={{
                                  position: "absolute",
                                  top: "0px",
                                  right: "0px",
                                }}
                                size="large"
                                onClick={() => {
                                  setProductState({
                                    ...productState,
                                    risks: {
                                      ...productState?.risks,
                                      covered: [
                                        ...(
                                          productState.risks?.covered || []
                                        )?.filter(
                                          (x) =>
                                            x.travelRiskId !==
                                            coveredItem.travelRiskId
                                        ),
                                      ],
                                    },
                                  });
                                }}
                              >
                                <CloseOutlined />
                              </Button>

                              <Space
                                direction="vertical"
                                style={{ width: "100%" }}
                              >
                                <Text>
                                  <b style={{ color: Colors.blue }}>
                                    {" "}
                                    #{coveredIndex + 1}
                                  </b>
                                  {" / " +
                                    t("description") +
                                    " / " +
                                    field_Language?.toUpperCase()}
                                </Text>
                                <TextArea
                                  size="large"
                                  placeholder={t("description")}
                                  autoSize={{ minRows: 2 }}
                                  value={
                                    coveredItem.translations?.[field_Language]
                                      ?.description || ""
                                  }
                                  onChange={(event) => {
                                    const editedNotCovered =
                                      productState.risks?.covered?.map((c) =>
                                        c.travelRiskId ===
                                        coveredItem.travelRiskId
                                          ? {
                                              ...c,
                                              translations: {
                                                ...c.translations,
                                                [field_Language]: {
                                                  ...c.translations?.[
                                                    field_Language
                                                  ],
                                                  description:
                                                    event.target.value,
                                                },
                                              },
                                            }
                                          : c
                                      );

                                    setRisksFields("covered", editedNotCovered);
                                  }}
                                />
                              </Space>
                            </Card>
                          );
                        }
                      )}
                    </GridMinMax>
                  </Flex>
                ),
              },
            ]}
          />
        )}

        <Collapse
          size="large"
          style={{ width: "100%" }}
          items={[
            {
              key: "3",
              label: t("whatIsNotCovered"),
              children: (
                <Flex vertical gap={20}>
                  <Space direction="vertical">
                    <Text>
                      {t("title") + " / " + field_Language?.toUpperCase()}
                    </Text>
                    <Input
                      size="large"
                      placeholder={
                        t("title") + " / " + field_Language?.toUpperCase()
                      }
                      value={
                        productState.risks?.translations?.[field_Language]
                          ?.coveredTitle || ""
                      }
                      onChange={(event) =>
                        setCoveredsTranslationFIelds(
                          "coveredTitle",
                          field_Language,
                          event?.target?.value
                        )
                      }
                    />
                  </Space>

                  <Divider orientation="left">
                    <Button
                      size="large"
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setProductState({
                          ...productState,
                          risks: {
                            ...productState.risks,
                            notCovered: [
                              ...(productState.risks?.notCovered || []),
                              {
                                id:
                                  1 *
                                  ((productState.risks?.notCovered?.length ||
                                    0) +
                                    1),
                                translations: {
                                  [field_Language]: {
                                    description: "",
                                  },
                                },
                              },
                            ],
                          },
                        });
                      }}
                    >
                      {t("add")}
                    </Button>
                  </Divider>

                  <GridMinMax>
                    {productState.risks?.notCovered?.map((nt, ntIndex) => {
                      return (
                        <Card key={ntIndex}>
                          <Button
                            type="link"
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "0px",
                            }}
                            onClick={() => {
                              setProductState({
                                ...productState,
                                risks: {
                                  ...productState?.risks,
                                  notCovered: [
                                    ...(
                                      productState.risks?.notCovered || []
                                    )?.filter((x) => x.id !== nt.id),
                                  ],
                                },
                              });
                            }}
                          >
                            <CloseOutlined />
                          </Button>

                          <Space direction="vertical" style={{ width: "100%" }}>
                            <Text>
                              <b style={{ color: Colors.blue }}>
                                #{ntIndex + 1}
                              </b>
                              {" / " +
                                t("description") +
                                " / " +
                                field_Language?.toUpperCase()}
                            </Text>
                            <TextArea
                              size="large"
                              placeholder={t("description")}
                              autoSize={{ minRows: 2 }}
                              value={
                                nt.translations?.[field_Language]
                                  ?.description || ""
                              }
                              onChange={(event) => {
                                const editedNotCovered =
                                  productState.risks?.notCovered?.map((c) =>
                                    c.id === nt.id
                                      ? {
                                          ...c,
                                          translations: {
                                            ...c.translations,
                                            [field_Language]: {
                                              ...c.translations?.[
                                                field_Language
                                              ],
                                              description: event.target.value,
                                            },
                                          },
                                        }
                                      : c
                                  );

                                setRisksFields("notCovered", editedNotCovered);
                              }}
                            />
                          </Space>
                        </Card>
                      );
                    })}
                  </GridMinMax>
                </Flex>
              ),
            },
          ]}
        />

        <Divider />

        <GridMinMax>
          <Button
            disabled={
              productState.type &&
              productState.translations &&
              productState.translations.ge?.title &&
              productState.translations.en?.title
                ? false
                : true
            }
            size="large"
            type="primary"
            onClick={
              productTypeStr === "store"
                ? _storeProductPageInfo
                : _updateProductPageInfo
            }
          >
            {productTypeStr === "store" ? `${t("create")}` : `${t("update")}`}
          </Button>

          {productTypeStr === "store" ? null : (
            <Popconfirm
              title={t("certainToDeleteIt")}
              onConfirm={_removeProductPageInfo}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
              placement="bottom"
            >
              <Button
                type="dashed"
                size="large"
                danger
                icon={<DeleteOutlined />}
              >
                {t("delete")}
              </Button>
            </Popconfirm>
          )}
        </GridMinMax>
      </Flex>
    </>
  );
};

export default Admin_products_edit;
