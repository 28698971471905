import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Flex, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import { GridMinMax } from "../../../assets/styles/GlobalStyle";
import Loader from "../../../components/Loader";
const { Text } = Typography;

const Admin_products_page = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    data: productsList,
    isLoading: isProductsListLoading,
    refetch,
  } = api.useGetAdminProductsListQuery(undefined);

  useEffect(() => {
    refetch();

    return () => {};
  }, [refetch]);

  return isProductsListLoading ? (
    <Loader />
  ) : (
    <>
      <Button
        size="large"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => navigate("/product-page-edit/store")}
      >
        {t("create")}
      </Button>

      <Divider />

      <GridMinMax align="stretch">
        {productsList?.map((item, index) => {
          return (
            <Card
              hoverable
              key={index}
              onClick={() => navigate(`/product-page-edit/${item.type}`)}
            >
              <Flex justify="center">
                <Text>
                  <b>{item?.type?.toUpperCase()}</b>
                </Text>
              </Flex>
            </Card>
          );
        })}
      </GridMinMax>
    </>
  );
};

export default Admin_products_page;
