import {
  AndroidOutlined,
  FacebookOutlined,
  FundProjectionScreenOutlined,
  GiftOutlined,
  GlobalOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  ProductOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Carousel, Flex, Skeleton, Statistic } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { GridMinMax } from "../../assets/styles/GlobalStyle";
import { useAppSelector } from "../../store";
import { AppState } from "../../store/appSlice";

const Admin_dashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lang } = useAppSelector((data: { app: AppState }) => data.app);

  const { data: companyList, isLoading: isCompanyListLoading } =
    api.useCompanyListQuery(undefined);

  const { data: webUsersList, isLoading: isWebUsersListLoading } =
    api.useGetWebUsersListQuery({
      query: `pageIndex=1&pageSize=10`,
    });

  const { data: franiUsersList, isLoading: isFraniUsersListLoading } =
    api.useGetFraniUsersListQuery(undefined);

  const { data: productList, isLoading: isPoroductListLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);

  const { data: PromoCodeList, isLoading: isPromoCodeListLoading } =
    api.useGetPromoCodesListQuery({
      query: "",
    });

  return (
    <Flex vertical gap={30}>
      <GridMinMax size={180} align="stretch">
        <Card
          bordered
          hoverable
          loading={isCompanyListLoading}
          onClick={() => navigate("/authorized-companies")}
        >
          <Statistic
            title={t("company")}
            value={companyList?.length}
            prefix={<FundProjectionScreenOutlined />}
          />
        </Card>

        <Card
          bordered
          hoverable
          loading={isWebUsersListLoading}
          onClick={() => navigate("/web-users")}
        >
          <Statistic
            title={t("user")}
            value={webUsersList?.totalCount}
            prefix={<TeamOutlined />}
          />
        </Card>

        <Card
          bordered
          hoverable
          loading={isFraniUsersListLoading}
          onClick={() => navigate("/frani-users")}
        >
          <Statistic
            title={t("administrator")}
            value={franiUsersList?.length}
            prefix={<UserOutlined />}
          />
        </Card>

        <Card bordered loading={isPoroductListLoading}>
          <Statistic
            title={t("product")}
            value={productList?.length}
            prefix={<ProductOutlined />}
          />
        </Card>
      </GridMinMax>

      {isCompanyListLoading ? (
        <Skeleton.Node active={true} style={{ width: "100%" }} />
      ) : (
        <Carousel
          fade
          autoplay
          waitForAnimate
          infinite={true}
          autoplaySpeed={2000}
          dots={false}
        >
          {companyList?.map((item, index) => (
            <Card key={index} hoverable={false}>
              <Flex gap={20} align="center" justify="space-between">
                <Flex gap={30} align="center">
                  <img
                    style={{
                      height: 40,
                      width: 40,
                      objectFit: "contain",
                    }}
                    src={item?.logo}
                    alt={`${item?.logo}-${index}`}
                  />
                  <Title style={{ marginBottom: 0 }} level={5}>
                    {item?.brandName?.[lang]?.title}
                  </Title>
                </Flex>

                <Button type="link">
                  <a
                    href={
                      item?.url?.startsWith("http")
                        ? item?.url
                        : `https://${item?.url}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item?.url}
                  </a>
                </Button>
              </Flex>
            </Card>
          ))}
        </Carousel>
      )}

      <GridMinMax size={70}>
        <Button
          size="large"
          type="default"
          onClick={() =>
            window.open("https://www.facebook.com/MyFrani", "_blank")
          }
        >
          <FacebookOutlined style={{ fontSize: 23 }} />
        </Button>

        <Button
          size="large"
          type="default"
          onClick={() =>
            window.open("https://www.instagram.com/frani_com", "_blank")
          }
        >
          <InstagramOutlined style={{ fontSize: 23 }} />
        </Button>

        <Button
          size="large"
          type="default"
          onClick={() =>
            window.open(
              "https://www.linkedin.com/company/frani-com/posts",
              "_blank"
            )
          }
        >
          <LinkedinOutlined style={{ fontSize: 23 }} />
        </Button>

        <Button
          size="large"
          type="default"
          onClick={() => window.open("https://frani.com/", "_blank")}
        >
          <GlobalOutlined style={{ fontSize: 23 }} />
        </Button>

        <Button
          size="large"
          type="default"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/datasafety?id=com.frani.twa",
              "_blank"
            )
          }
        >
          <AndroidOutlined style={{ fontSize: 23 }} />
        </Button>
      </GridMinMax>

      <GridMinMax size={180}>
        <Card
          size="small"
          bordered
          hoverable
          loading={isPromoCodeListLoading}
          onClick={() => navigate("/promo-codes")}
        >
          <Statistic
            title={t("active")}
            value={
              PromoCodeList?.filter((item) => item.status === "active")?.length
            }
            prefix={<GiftOutlined />}
          />
        </Card>

        <Card
          size="small"
          bordered
          hoverable
          loading={isPromoCodeListLoading}
          onClick={() => navigate("/promo-codes")}
        >
          <Statistic
            title={t("prepared")}
            value={
              PromoCodeList?.filter((item) => item.status === "prepared")
                ?.length
            }
            prefix={<GiftOutlined />}
          />
        </Card>

        <Card
          size="small"
          bordered
          hoverable
          loading={isPromoCodeListLoading}
          onClick={() => navigate("/promo-codes")}
        >
          <Statistic
            title={t("draft")}
            value={
              PromoCodeList?.filter((item) => item.status === "draft")?.length
            }
            prefix={<GiftOutlined />}
          />
        </Card>

        <Card
          size="small"
          bordered
          hoverable
          loading={isPromoCodeListLoading}
          onClick={() => navigate("/promo-codes")}
        >
          <Statistic
            title={t("expired")}
            value={
              PromoCodeList?.filter((item) => item.status === "expired")?.length
            }
            prefix={<GiftOutlined />}
          />
        </Card>
      </GridMinMax>
    </Flex>
  );
};

export default Admin_dashboard;
