import { Divider, Flex, Steps, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { ScheduleType, currencySymbol } from "../domain/types";

const { Text } = Typography;

const AdminPaymentSchedule: React.FC<{
  scheduleData?: ScheduleType;
}> = ({ scheduleData }) => {
  const { t } = useTranslation();

  return scheduleData ? (
    <>
      <Flex justify="space-between">
        <Text>
          {t("nextPayment")}:{" "}
          {dayjs(scheduleData?.nextPaymentDate).format("DD / MMM / YYYY")}
        </Text>
        <Text>
          {t("haveToPayment")}:{" "}
          {scheduleData?.nextPaymentAmount +
            currencySymbol(scheduleData.currency)}
        </Text>
        <Text>
          {t("totalPayment")}:{" "}
          {scheduleData?.totalPaymentAmount +
            currencySymbol(scheduleData.currency)}
        </Text>
      </Flex>

      <Divider variant="dashed" />

      <Steps
        direction="vertical"
        responsive={true}
        size="small"
        items={scheduleData?.schedule.map((item) => ({
          title: item.name,
          status:
            item.passed === false && item.active === true
              ? "process"
              : item.passed === true && item.active === false
              ? "finish"
              : "wait",
          description: `${t("amount")}: ${
            item?.amount + currencySymbol(scheduleData.currency)
          }`,
        }))}
      />
    </>
  ) : null;
};

export default AdminPaymentSchedule;
