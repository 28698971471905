import {
  ArrowLeftOutlined,
  CaretRightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Collapse,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../../api";
import Colors from "../../../../assets/styles/Colors";
import AdminPaymentSchedule from "../../../../components/AdminPaymentSchedule";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import {
  DeactivatedPolicy,
  EnabledLanguage,
  PetDetailPageType,
  ScheduleType,
  currencySymbol,
  georgianFormatter,
} from "../../../../domain/types";
import { RootState, useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
import { AuthState } from "../../../../store/authSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
const { Text } = Typography;

const AdminTplSoldPolicyDetail = () => {
  const { policyId: policyIdStr = "0" } = useParams();
  const policyId = parseInt(policyIdStr);
  const { field_Language, lang } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  const navigate = useNavigate();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { t } = useTranslation();
  const [activePolicyStatus, setActivePolicyStatus] = useState("");
  const [drawerToggle, setDrawerToggle] = useState<boolean>(false);

  // active policy mutation
  const [activePolicy, { isLoading: isActivePolicyLoading }] =
    api.useActivePolicyMutation();

  // deactive policy mutation
  const [deActivePolicy, { isLoading: isDeActivePolicyLoading }] =
    api.useDeactivePolicyMutation();

  // countries list
  const { data: countries = [], isLoading: isCountriesLoading } =
    api.useGetCountriesQuery(undefined);

  const {
    data: adminPolicyDetail,
    isLoading: isAdminPolicyDetailLoading,
    refetch: refetchAdminPolicy,
  } = api.useGetAdminPolicyDetailQuery(policyId, {
    skip:
      auth.user?.userType === "Frani-Admin" ||
      auth.user?.userType === "Frani-Product-Owner"
        ? false
        : true,
  });

  // get travel policy deactive reason
  const { data: deactiveReasonArr, isLoading: isDeaactiveReasonArrLoading } =
    api.useGetAdminPolicyDeactiveReasonQuery(
      {
        categoryId: (adminPolicyDetail?.productCategoryId as number) || 0,
        isActive: true,
      },
      {
        skip: adminPolicyDetail?.productCategoryId ? false : true,
      }
    );

  const { data: companyPolicyDetail, isLoading: isCompanyPolicyDetailLoading } =
    api.useGetAdminPolicyByIdQuery(policyId, {
      skip: auth.user?.userType === "Insurance" && policyId ? false : true,
    });

  const [deactiveInfo, setDeactiveInfo] = useState<Partial<DeactivatedPolicy>>({
    policyId: policyId,
  });

  const setDeactiveFields = (field: keyof DeactivatedPolicy, value: any) => {
    setDeactiveInfo({
      ...deactiveInfo,
      [field]: value,
    });
  };

  const setDeactiveTranslatedField = (
    field: keyof DeactivatedPolicy,
    language: EnabledLanguage,
    value: any
  ) => {
    setDeactiveInfo({
      ...deactiveInfo,
      translations: {
        ...deactiveInfo.translations,
        [language]: {
          ...deactiveInfo?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  const _activePolicyFuntion = async (
    policyId: number,
    policyDeactivationId: number,
    policyStatus: string
  ) => {
    if (policyId && policyDeactivationId && policyStatus) {
      try {
        await activePolicy({
          policyId,
          policyDeactivationId,
          policyStatus,
        }).unwrap();
        toast.success(`${t("policy")} ${t("activatedSuccessfully")}`);
        refetchAdminPolicy();
        setDrawerToggle(false);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  const _deActivePolicyFuntion = async () => {
    if (deactiveInfo) {
      try {
        await deActivePolicy({
          cancellationPenalty: Number(deactiveInfo.cancellationPenalty),
          comment: deactiveInfo.comment,
          deactivatedDate: deactiveInfo.deactivatedDate,
          docUrl: deactiveInfo.docUrl,
          earnedPremium: Number(deactiveInfo.earnedPremium),
          policyId: policyId,
          policyDeactivationReasonId: deactiveInfo.policyDeactivationReasonId,
          refundAmount: Number(deactiveInfo.refundAmount),
          translations: deactiveInfo.translations,
        }).unwrap();

        toast.success(`${t("policy")} ${t("deactivated")} !`);
        refetchAdminPolicy();
        setDrawerToggle(false);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  const policy: PetDetailPageType =
    (adminPolicyDetail as never as PetDetailPageType) ||
    (companyPolicyDetail as never as PetDetailPageType);

  const startDate = dayjs(policy?.mainPolicy?.startDate);
  const endDate = dayjs(policy?.mainPolicy?.endDate);

  const insuranceDays = endDate.diff(startDate, "day");
  const orderId = policy?.mainPolicy?.orderItems?.[0]?.orderId;
  const discount = policy?.mainPolicy?.orderItems?.[0]?.order?.discount;
  const { data: paymentScheduleData, isLoading: isPaymentScheduleDataLoading } =
    api.useGetAdminPetPaymentScheduleQuery(
      {
        orderId,
      },
      {
        skip: orderId ? false : true,
      }
    );

  const { data: paymentTransactions, isLoading: isPaymentTransactionLoading } =
    api.useGetAdminPetTransactionsQuery(
      {
        orderId,
      },
      {
        skip: orderId ? false : true,
      }
    );

  const _scheduleArray = () => {
    if (paymentScheduleData) {
      const formatedData: ScheduleType = {
        nextPaymentAmount: georgianFormatter.format(
          paymentScheduleData.nextPaymentAmount
        ),
        totalPaymentAmount: georgianFormatter.format(
          paymentScheduleData.totalRemainedPaymentAmount
        ),
        nextPaymentDate: paymentScheduleData.nextPaymentDate,
        currency: paymentScheduleData.currency,
        schedule: paymentScheduleData.payments?.map((item) => ({
          amount: georgianFormatter.format(item.amount),
          name: dayjs(
            item.details?.scheduledPaymentDate ?? item.details?.paymentDate
          ).format("MMM"),
          passed: item.status === "paid" ? true : false,
          active:
            dayjs(paymentScheduleData.nextPaymentDate).format("MMMM") ===
              dayjs(item.details.paymentDate).format("MMMM") &&
            item.status !== "paid"
              ? true
              : false,
        })),
      };
      return formatedData;
    } else {
      return undefined;
    }
  };

  const paymentScheduleArray = _scheduleArray();

  // main loading
  const MainLoading =
    isAdminPolicyDetailLoading ||
    isCompanyPolicyDetailLoading ||
    isActivePolicyLoading ||
    isDeActivePolicyLoading ||
    isPaymentTransactionLoading ||
    isDeaactiveReasonArrLoading ||
    isCountriesLoading;

  const columns = [
    {
      title: t("company"),
      key: "company",
      dataIndex: "company",
    },
    {
      title: t("insured"),
      key: "insured",
      dataIndex: "insured",
    },
    {
      title: t("policy") + " N",
      key: "policy",
      dataIndex: "policy",
    },
    {
      title: t("date"),
      key: "date",
      dataIndex: "date",
    },
    {
      title: t("value"),
      key: "value",
      dataIndex: "value",
    },
    {
      title: t("status"),
      key: "status",
      dataIndex: "status",
    },
  ];

  return (
    <>
      {MainLoading ? (
        <Loader />
      ) : (
        <>
          <Card size="small">
            <Flex justify="space-between" align="center">
              <Button
                size="large"
                type="link"
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
              >
                {t("back")}
              </Button>

              {(auth.user?.userType === "Frani-Admin" ||
                auth.user?.userType === "Frani-Product-Owner") && (
                <Button
                  size="large"
                  type="dashed"
                  onClick={() => {
                    setDrawerToggle(!drawerToggle);
                    setDeactiveFields(
                      "refundAmount",
                      Number(policy?.refundAmount || 0)
                    );
                  }}
                >
                  {policy?.mainPolicy?.deactivatedPolicy
                    ? `${t("policyActivation")}`
                    : `${t("policyDeactivation")}`}
                </Button>
              )}
            </Flex>
          </Card>

          {/* POLICY DEACTIVE FORM TOP SIDE */}
          <Drawer
            extra={<AdminLangSwicher />}
            title={
              policy?.mainPolicy?.deactivatedPolicy
                ? `${t("policyActivation")}`
                : `${t("policyDeactivation")}`
            }
            onClose={() => setDrawerToggle(!drawerToggle)}
            open={drawerToggle}
          >
            {policy?.mainPolicy?.deactivatedPolicy ? (
              <>
                {/* IS ACTIVATION POLICY FORM */}
                <Flex gap={20} wrap="wrap">
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("choosePolicyStatus")}</Text>
                    <Select
                      style={{ width: "100%" }}
                      placeholder={t("choosePolicyStatus")}
                      size="large"
                      value={activePolicyStatus}
                      onChange={(value) => setActivePolicyStatus(value)}
                      options={[
                        ...["ACTIVE", "CLOSED", "EXPIRED", "PENDING"].map(
                          (item) => ({
                            value: item,
                            label: item,
                          })
                        ),
                      ]}
                    />
                  </Space>
                </Flex>

                <Divider />

                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  disabled={activePolicyStatus ? false : true}
                  onClick={() => {
                    if (
                      activePolicyStatus &&
                      policyId &&
                      policy?.mainPolicy?.deactivatedPolicy?.id &&
                      window.confirm(`${t("certainToChangeStatus")}`)
                    )
                      _activePolicyFuntion(
                        policyId,
                        policy?.mainPolicy?.deactivatedPolicy?.id,
                        activePolicyStatus
                      );
                  }}
                >
                  {t("submit")}
                </Button>
                {/* IS ACTIVATION POLICY FORM */}
              </>
            ) : (
              <>
                {/* IS DEACTIVE POLICY FORM */}
                <Flex gap={20} wrap="wrap">
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("selectDeactivationDate")}</Text>
                    <Input
                      size="large"
                      type="date"
                      placeholder={t("selectDeactivationDate")}
                      value={deactiveInfo.deactivatedDate || ""}
                      onChange={(event) =>
                        setDeactiveFields(
                          "deactivatedDate",
                          dayjs(event?.target?.value).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </Space>

                  {deactiveReasonArr && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Text>{t("deactivationReason")}</Text>
                      <Select
                        style={{ width: "100%" }}
                        placeholder={t("deactivationReason")}
                        size="large"
                        value={deactiveInfo.policyDeactivationReasonId}
                        onChange={(value) =>
                          setDeactiveFields("policyDeactivationReasonId", value)
                        }
                        options={[
                          ...deactiveReasonArr.map((item) => ({
                            value: item.id,
                            label: item?.translations?.[field_Language]?.title,
                          })),
                        ]}
                      />
                    </Space>
                  )}

                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("earnedPremium")}</Text>
                    <Input
                      size="large"
                      type="number"
                      placeholder={t("earnedPremium")}
                      value={deactiveInfo.earnedPremium || ""}
                      onChange={(event) =>
                        setDeactiveFields("earnedPremium", event?.target?.value)
                      }
                    />
                  </Space>

                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("deactivationPenalty")}</Text>
                    <Input
                      size="large"
                      type="number"
                      placeholder={t("deactivationPenalty")}
                      value={deactiveInfo.cancellationPenalty || ""}
                      onChange={(event) =>
                        setDeactiveFields(
                          "cancellationPenalty",
                          event?.target?.value
                        )
                      }
                    />
                  </Space>

                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("refundableAmount")}</Text>
                    <Input
                      size="large"
                      type="number"
                      placeholder={t("refundableAmount")}
                      value={deactiveInfo.refundAmount || ""}
                      onChange={(event) =>
                        setDeactiveFields("refundAmount", event?.target?.value)
                      }
                    />
                  </Space>

                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("comment")}</Text>
                    <TextArea
                      size="large"
                      placeholder={t("comment")}
                      autoSize={{ minRows: 2 }}
                      value={deactiveInfo.translations?.[field_Language]?.title}
                      onChange={(event) =>
                        setDeactiveTranslatedField(
                          "comment",
                          field_Language,
                          event.target.value
                        )
                      }
                    />
                  </Space>

                  <UploadFile
                    label={t("attachDocument")}
                    onChange={(props) => setDeactiveFields("docUrl", props.url)}
                    defaultFileName={deactiveInfo?.docUrl || ""}
                  />
                </Flex>

                <Divider />

                <Popconfirm
                  title={t("certainToDeactivate")}
                  onConfirm={_deActivePolicyFuntion}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="top"
                >
                  <Button
                    style={{ width: "100%" }}
                    size="large"
                    type="primary"
                    disabled={
                      deactiveInfo.policyDeactivationReasonId &&
                      deactiveInfo.deactivatedDate &&
                      deactiveInfo.cancellationPenalty &&
                      deactiveInfo.policyId &&
                      deactiveInfo.earnedPremium
                        ? false
                        : true
                    }
                  >
                    {t("policyDeactivate")}
                  </Button>
                </Popconfirm>

                {/* IS DEACTIVE POLICY FORM */}
              </>
            )}
          </Drawer>
          {/* POLICY DEACTIVE FORM BOTTOM SIDE */}

          <Tabs
            style={{ marginTop: 20 }}
            defaultActiveKey="1"
            tabPosition="top"
            size="middle"
            items={[
              {
                key: "1",
                label: t("policyInfo"),
                children: (
                  <>
                    <Flex gap={20} wrap="wrap">
                      <Collapse
                        size="large"
                        style={{ width: "100%" }}
                        items={[
                          {
                            key: "1",
                            label: t("insurerInformation"),
                            children: (
                              <Flex vertical gap={10}>
                                <Flex justify="space-between">
                                  <Text>{t("firstName")}</Text>
                                  <Text>
                                    {policy?.mainPolicy?.user?.firstNameGe ??
                                      policy?.mainPolicy?.user?.firstName}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("lastName")}</Text>
                                  <Text>
                                    {policy?.mainPolicy?.user?.lastNameGe ??
                                      policy?.mainPolicy?.user?.lastName}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("gender")}</Text>
                                  <Text>
                                    {policy?.mainPolicy?.user?.gender ??
                                      policy?.mainPolicy?.user?.gender}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("personalNumber")}</Text>
                                  <Text>
                                    {policy?.mainPolicy?.user?.personalNumber}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("dateOfBirth")}</Text>
                                  <Text>
                                    {dayjs(
                                      policy?.mainPolicy?.user?.birthday
                                    ).format("DD / MMM / YYYY")}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("phone")}</Text>
                                  <Text>{policy?.mainPolicy?.user?.phone}</Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("email")}</Text>
                                  <Text>{policy?.mainPolicy?.user?.email}</Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("cityzenship")}</Text>
                                  <Text>
                                    {(countries || [])?.find(
                                      (x) =>
                                        x.isoCode ===
                                        policy?.mainPolicy?.user?.citizenship
                                    )?.translations?.[lang]?.name || "Unknown"}
                                  </Text>
                                </Flex>
                              </Flex>
                            ),
                          },
                        ]}
                      />

                      <Collapse
                        size="large"
                        style={{ width: "100%" }}
                        items={[
                          {
                            key: "2",
                            label: t("policyInfo"),
                            children: (
                              <Flex vertical gap={10}>
                                <Flex justify="space-between">
                                  <Text>{t("insuranceCompany")}</Text>
                                  <Text>
                                    {
                                      policy?.mainPolicy?.package?.product
                                        ?.company?.translations[lang]?.title
                                    }
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("policyType")}</Text>
                                  <Text>
                                    {String(
                                      policy?.mainPolicy?.targetType?.match(
                                        /[A-Z][a-z]+/g
                                      )
                                    )?.replace(",", " ")}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("policyNumber")}</Text>
                                  <Text>
                                    {policy?.mainPolicy?.policyNumber}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("startDate")}</Text>
                                  <Text>
                                    {dayjs(
                                      policy?.mainPolicy?.startDate
                                    ).format("DD MMMM YYYY")}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("endDate")}</Text>
                                  <Text>
                                    {dayjs(policy?.mainPolicy?.endDate).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("numberOfDays")}</Text>
                                  <Text>
                                    {insuranceDays} {t("day")}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("orderId")}</Text>
                                  <Text>
                                    {policy?.mainPolicy?.orderItems[0]?.orderId}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("releaseDate")}</Text>
                                  <Text>
                                    {dayjs(
                                      policy?.mainPolicy?.createdAt
                                    ).format("DD MMMM YYYY | HH:mm")}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("status")}</Text>
                                  <Text>
                                    {policy?.mainPolicy?.status?.toUpperCase()}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("policyPrice")}</Text>
                                  <Text>
                                    {discount === null
                                      ? `${policy?.price} ${currencySymbol(
                                          policy?.mainPolicy
                                            ?.compensationCurrency
                                        )}`
                                      : `${
                                          discount?.promoCode?.applyingDiscount
                                            ?.actualAmount
                                        }  ${currencySymbol(
                                          policy?.mainPolicy
                                            ?.compensationCurrency
                                        )}`}{" "}
                                  </Text>
                                </Flex>

                                {discount !== null && (
                                  <>
                                    <Flex justify="space-between">
                                      <Text>{t("promoCode")}</Text>
                                      <Text>{discount?.promoCode?.code}</Text>
                                    </Flex>
                                    <Flex justify="space-between">
                                      <Text>{t("promoCodeName")}</Text>
                                      <Text>{discount?.promoCode?.name}</Text>
                                    </Flex>

                                    {discount?.promoCode?.applyingDiscount
                                      ?.days && (
                                      <Flex justify="space-between">
                                        <Text>{t("discountByDays")}</Text>
                                        <Text>
                                          {`${
                                            discount?.promoCode
                                              ?.applyingDiscount?.days
                                          } ${t("day")}`}{" "}
                                        </Text>
                                      </Flex>
                                    )}

                                    {discount?.promoCode?.applyingDiscount
                                      ?.saving && (
                                      <Flex justify="space-between">
                                        <Text>{t("discountByAmount")}</Text>
                                        <Text>
                                          {
                                            discount?.promoCode
                                              ?.applyingDiscount?.saving
                                          }{" "}
                                          {currencySymbol(
                                            policy?.mainPolicy
                                              ?.compensationCurrency
                                          )}
                                        </Text>
                                      </Flex>
                                    )}

                                    {discount?.promoCode?.applyingDiscount
                                      ?.percentageDiscount?.percentage && (
                                      <>
                                        <Flex justify="space-between">
                                          <Text>{t("discountByPercent")}</Text>
                                          <Text>
                                            {`${discount?.promoCode?.applyingDiscount?.percentageDiscount?.percentage} %`}{" "}
                                          </Text>
                                        </Flex>
                                        <Flex justify="space-between">
                                          <Text>{t("maximumAmount")}</Text>
                                          <Text>
                                            {`${discount?.promoCode?.applyingDiscount?.percentageDiscount?.maximumAmount} `}{" "}
                                          </Text>
                                        </Flex>
                                      </>
                                    )}
                                  </>
                                )}

                                <Flex justify="space-between">
                                  <Text>{t("paymentSchedule")}</Text>
                                  <Text>
                                    {
                                      policy?.mainPolicy?.orderItems?.[0]
                                        ?.paymentScheduleType?.translations?.[
                                        lang
                                      ]?.title
                                    }
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("firstPayment")}</Text>
                                  <Text>
                                    {georgianFormatter.format(
                                      paymentScheduleData?.payments?.[0]
                                        ?.amount as number
                                    )}{" "}
                                    {currencySymbol(
                                      policy?.mainPolicy?.compensationCurrency
                                    )}
                                  </Text>
                                </Flex>

                                {policy?.mainPolicy?.orderItems?.[0]?.paymentScheduleType?.slug?.toLowerCase() ===
                                "one-time" ? null : (
                                  <Flex justify="space-between">
                                    <Text>{t("eachPayment")}</Text>
                                    <Text>
                                      {georgianFormatter.format(
                                        (
                                          paymentScheduleData?.payments || []
                                        ).slice(-1)[0]?.amount
                                      )}{" "}
                                      {currencySymbol(
                                        policy?.mainPolicy?.compensationCurrency
                                      )}
                                    </Text>
                                  </Flex>
                                )}

                                <Flex justify="space-between">
                                  <Text>{t("pan")}</Text>
                                  <Text>
                                    {policy?.mainPolicy?.orderItems?.[0]?.order?.payments?.map(
                                      (p) => {
                                        return p?.status === "paid"
                                          ? p?.detailJson?.pan
                                          : "";
                                      }
                                    )}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("accountId")}</Text>
                                  <Text>
                                    {policy?.mainPolicy?.orderItems?.[0]?.order?.payments?.map(
                                      (p) => {
                                        return p?.status === "paid"
                                          ? p?.detailJson?.accountId
                                          : "";
                                      }
                                    )}
                                  </Text>
                                </Flex>

                                {policy?.mainPolicy?.deactivatedPolicy && (
                                  <div>
                                    <hr
                                      style={{
                                        color: Colors.primaryBlack,
                                      }}
                                    />

                                    <Flex justify="space-between">
                                      <Text>{t("dateOfDeactivation")}</Text>
                                      <Text>
                                        {dayjs(
                                          policy?.mainPolicy?.deactivatedPolicy
                                            ?.deactivatedDate
                                        ).format("DD / MMMM / YYYY")}
                                      </Text>
                                    </Flex>

                                    <Flex justify="space-between">
                                      <Text>
                                        {t("deactivationRequestDate")}
                                      </Text>
                                      <Text>
                                        {dayjs(
                                          policy?.mainPolicy?.deactivatedPolicy
                                            ?.createdAt
                                        ).format("DD / MMMM / YYYY | HH:mm")}
                                      </Text>
                                    </Flex>

                                    <Flex justify="space-between">
                                      <Text>{t("refundableAmount")}</Text>
                                      <Text>
                                        {
                                          policy?.mainPolicy?.deactivatedPolicy
                                            ?.refundAmount
                                        }
                                      </Text>
                                    </Flex>

                                    <Flex justify="space-between">
                                      <Text>{t("earnedPremium")}</Text>
                                      <Text>
                                        {
                                          policy?.mainPolicy?.deactivatedPolicy
                                            ?.earnedPremium
                                        }
                                      </Text>
                                    </Flex>

                                    <Flex justify="space-between">
                                      <Text>{t("deactivationPenalty")}</Text>
                                      <Text>
                                        {
                                          policy?.mainPolicy?.deactivatedPolicy
                                            ?.cancellationPenalty
                                        }
                                      </Text>
                                    </Flex>

                                    <Flex justify="space-between">
                                      <Text>{t("document")}</Text>
                                      <Text>
                                        <a
                                          href={
                                            policy?.mainPolicy
                                              ?.deactivatedPolicy?.docUrl
                                          }
                                          target={"_blank"}
                                          rel="noreferrer"
                                        >
                                          {
                                            policy?.mainPolicy
                                              ?.deactivatedPolicy?.docUrl
                                          }
                                        </a>
                                      </Text>
                                    </Flex>

                                    <Flex justify="space-between">
                                      <Text>{t("decativationReason")}</Text>
                                      <Text>
                                        {
                                          deactiveReasonArr?.find(
                                            (x) =>
                                              x?.id ===
                                              policy?.mainPolicy
                                                ?.deactivatedPolicy
                                                ?.policyDeactivationReasonId
                                          )?.translations?.[lang]?.title
                                        }
                                      </Text>
                                    </Flex>

                                    <Flex justify="space-between">
                                      <Text>{t("comment")}</Text>
                                      <Text>
                                        {
                                          policy?.mainPolicy?.deactivatedPolicy
                                            ?.comment
                                        }
                                      </Text>
                                    </Flex>
                                  </div>
                                )}
                              </Flex>
                            ),
                          },
                        ]}
                      />

                      <Collapse
                        size="large"
                        style={{ width: "100%" }}
                        items={[
                          {
                            key: "3",
                            label: t("petInformation"),
                            children: (
                              <Flex vertical gap={10}>
                                <Flex justify="space-between">
                                  <Text>{t("category")}</Text>
                                  <Text>
                                    {policy?.animalCategory?.translations[lang]
                                      ?.name ??
                                      policy?.animalCategory?.translations[lang]
                                        ?.name}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("firstName")}</Text>
                                  <Text>
                                    {policy?.petInformation?.name ??
                                      policy?.petInformation?.name}
                                  </Text>
                                </Flex>
                                <Flex justify="space-between">
                                  <Text>{t("age")}</Text>
                                  <Text>
                                    {policy?.petInformation?.age?.year >= 1 &&
                                    policy?.petInformation?.age?.month === 0
                                      ? policy?.petInformation?.age?.year +
                                        " " +
                                        t("year")
                                      : policy?.petInformation?.age?.month >=
                                          1 &&
                                        policy?.petInformation?.age?.year === 0
                                      ? policy?.petInformation?.age?.month +
                                        " " +
                                        t("month")
                                      : policy?.petInformation?.age?.year >=
                                          1 &&
                                        policy?.petInformation?.age?.month >= 1
                                      ? policy?.petInformation?.age?.year +
                                        " " +
                                        t("year") +
                                        ", " +
                                        policy?.petInformation?.age?.month +
                                        " " +
                                        t("month")
                                      : null}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("breed")}</Text>
                                  <Text>
                                    {policy?.breed?.translations[lang]?.name ??
                                      policy?.breed?.translations[lang]?.name}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("color")}</Text>
                                  <Text>
                                    {policy?.petInformation?.color ??
                                      policy?.petInformation?.color}
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("gender")}</Text>
                                  <Text>
                                    {policy?.petInformation?.gender === "female"
                                      ? t("petFemale")
                                      : t("petMale")}
                                  </Text>
                                </Flex>
                              </Flex>
                            ),
                          },
                        ]}
                      />

                      <Collapse
                        size="large"
                        style={{ width: "100%" }}
                        items={[
                          {
                            key: "4",
                            label: t("whatIsCovered"),
                            children: (
                              <Flex vertical gap={10}>
                                {policy.mainPolicy?.package?.target?.petCoverages?.map(
                                  (item, index) => (
                                    <Flex justify="space-between" key={index}>
                                      <Text>
                                        {item.translations?.[lang]?.title}
                                      </Text>
                                      <Text>
                                        {item.translations?.[lang]?.body}
                                      </Text>
                                    </Flex>
                                  )
                                )}
                              </Flex>
                            ),
                          },
                        ]}
                      />

                      <Collapse
                        size="large"
                        style={{ width: "100%" }}
                        items={[
                          {
                            key: "5",
                            label: t("importantInformation"),
                            children: (
                              <Flex vertical gap={10}>
                                {policy.mainPolicy?.package?.target?.petImportantTerms?.map(
                                  (item, index) => (
                                    <Flex gap={20} key={index}>
                                      <CaretRightOutlined />
                                      <Text>
                                        {item?.translations?.[lang]?.body}
                                      </Text>
                                    </Flex>
                                  )
                                )}
                              </Flex>
                            ),
                          },
                        ]}
                      />

                      <Collapse
                        size="large"
                        style={{ width: "100%" }}
                        items={[
                          {
                            key: "6",
                            label: t("documents"),
                            children: (
                              <Flex vertical gap={10}>
                                <Flex justify="space-between">
                                  <Text>{t("insuranceContract")}</Text>
                                  <Text>
                                    <a
                                      // href={policy?.mainPolicy?.package?.wordingFileUrl ?? policy?.mainPolicy?.productWordingUrl}
                                      href={
                                        policy?.mainPolicy?.productWordingUrl
                                      }
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      {/* {policy?.mainPolicy?.package?.wordingFileUrl ?? policy?.mainPolicy?.productWordingUrl} */}
                                      {policy?.mainPolicy?.productWordingUrl}
                                    </a>
                                  </Text>
                                </Flex>

                                <Flex justify="space-between">
                                  <Text>{t("policyAgreement")}</Text>
                                  <Text>
                                    <a
                                      href={policy?.mainPolicy?.policyPdfUrl}
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      {policy?.mainPolicy?.policyPdfUrl}
                                    </a>
                                  </Text>
                                </Flex>
                              </Flex>
                            ),
                          },
                        ]}
                      />
                    </Flex>
                  </>
                ),
              },
              {
                key: "2",
                label: t("transactions"),
                children: (
                  <>
                    {policy?.mainPolicy?.orderItems?.[0]?.paymentScheduleType?.slug?.toLowerCase() ===
                    "one-time" ? null : (
                      <>
                        {isPaymentScheduleDataLoading ? (
                          <Flex
                            justify="center"
                            align="center"
                            style={{ minHeight: "100px", height: "100%" }}
                          >
                            <Spin
                              indicator={<LoadingOutlined spin />}
                              size="large"
                            />
                          </Flex>
                        ) : (
                          <>
                            <Collapse
                              size="large"
                              items={[
                                {
                                  key: "1",
                                  label: t("paymentSchedule"),
                                  children: (
                                    <AdminPaymentSchedule
                                      scheduleData={paymentScheduleArray}
                                    />
                                  ),
                                },
                              ]}
                            />
                          </>
                        )}

                        <Divider />
                      </>
                    )}

                    <Table
                      scroll={{ x: "max-content" }}
                      pagination={false}
                      columns={columns}
                      dataSource={
                        (paymentTransactions &&
                          paymentTransactions.map((item, index) => ({
                            key: index,
                            company: item?.companyTranslations?.[lang]?.title,
                            insured: item?.carRegistrationNumber,
                            policy: item?.policyNumber,
                            date: dayjs(item.transferDate)?.format(
                              "DD.MM.YYYY"
                            ),
                            value: georgianFormatter.format(
                              item.transferAmount as number
                            ),
                            status: item?.status?.toUpperCase(),
                          }))) ||
                        []
                      }
                    />
                  </>
                ),
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default AdminTplSoldPolicyDetail;
