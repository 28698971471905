import { Button, Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const NotFound404: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Result
        status="404"
        title="404"
        subTitle={t("pageNotLoad")}
        extra={
          <Button type="primary" onClick={() => navigate("/dashboard")}>
            {t("goToDashboard")}
          </Button>
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
`;

export default NotFound404;
