import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Collapse, Divider, Flex, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../api";
import Loader from "../../../../components/Loader";
import { RootState } from "../../../../store";
import { AuthState } from "../../../../store/authSlice";
const { Text } = Typography;

const AdminBusinessModuleDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { itemId: moduleIdStr = "0" } = useParams();
  const moduleId = parseInt(moduleIdStr);
  const isFraniAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";

  const {
    data: corporateModuleDetail,
    isLoading: corporateModuleDetailsLoading,
  } = api.useGetBusinessModuleByIdQuery(moduleId);

  const corporateModuleDetails = corporateModuleDetail
    ? corporateModuleDetail[0]
    : undefined;

  return corporateModuleDetailsLoading ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between" align="center">
        <Button
          size="large"
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <Title level={3}>
          {corporateModuleDetails?.firstname && corporateModuleDetails?.lastname
            ? corporateModuleDetails?.firstname +
              " " +
              corporateModuleDetails?.lastname
            : " "}
        </Title>
      </Flex>

      <Divider />

      {isFraniAdmin && (
        <Flex vertical gap={20}>
          <Collapse
            size="large"
            style={{ width: "100%" }}
            items={[
              {
                key: "1",
                label: t("companyInformation"),
                children: (
                  <Flex vertical gap={10}>
                    <Flex justify="space-between">
                      <Text>{t("companyName")}</Text>
                      <Text>
                        {corporateModuleDetails?.companyName ??
                          corporateModuleDetails?.companyName}
                      </Text>
                    </Flex>

                    <Flex justify="space-between">
                      <Text>{t("companyID")}</Text>
                      <Text>
                        {corporateModuleDetails?.idNumber ??
                          corporateModuleDetails?.idNumber}
                      </Text>
                    </Flex>

                    <Flex justify="space-between">
                      <Text>{t("activityField")}</Text>
                      <Text>
                        {corporateModuleDetails?.activityField ??
                          corporateModuleDetails?.activityField}
                      </Text>
                    </Flex>
                  </Flex>
                ),
              },
            ]}
          />

          {corporateModuleDetails.type === "health" && (
            <Collapse
              size="large"
              style={{ width: "100%" }}
              items={[
                {
                  key: "2",
                  label: t("healthInsurance"),
                  children: (
                    <Flex vertical gap={10}>
                      <Flex justify="space-between">
                        <Text>{t("employeeNumber")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .employeesCount ??
                            corporateModuleDetails?.otherInformation
                              .employeesCount}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("insuredEmployees")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .insuredEmployeesCount ??
                            corporateModuleDetails?.otherInformation
                              .insuredEmployeesCount}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("managementNumber")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .topManagementCount ??
                            corporateModuleDetails?.otherInformation
                              .topManagementCount}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("employeeAverageAge")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .averageAge ??
                            corporateModuleDetails?.otherInformation.averageAge}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("genderBalance")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .genderFemale &&
                          corporateModuleDetails?.otherInformation.genderMale
                            ? `${t("female")} : ${
                                corporateModuleDetails?.otherInformation
                                  .genderFemale
                              }  ${t("male")}: ${
                                corporateModuleDetails?.otherInformation
                                  .genderMale
                              }`
                            : ""}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("minPriceInsurance")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .minInsurancePackage ??
                            corporateModuleDetails?.otherInformation
                              .minInsurancePackage}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("insurancePayer")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .insurancePayer ??
                            corporateModuleDetails?.otherInformation
                              .insurancePayer}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("insuredRecently")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .usedInsuranceLastYear
                            ? corporateModuleDetails?.otherInformation
                                .usedInsuranceLastYear === true
                              ? `${t("yes")}`
                              : `${t("no")}`
                            : ""}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("insuredCompanyName")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .insuranceCompanyName ??
                            corporateModuleDetails?.otherInformation
                              .insuranceCompanyName}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("contractRenewalDate")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .contractRenewalDate ??
                            corporateModuleDetails?.contractRenewalDate}
                        </Text>
                      </Flex>
                    </Flex>
                  ),
                },
              ]}
            />
          )}

          {corporateModuleDetails.type === "car" && (
            <Collapse
              size="large"
              style={{ width: "100%" }}
              items={[
                {
                  key: "3",
                  label: t("carInsurance"),
                  children: (
                    <Flex vertical gap={10}>
                      <Flex justify="space-between">
                        <Text>{t("carBrand")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation.carBrand ??
                            corporateModuleDetails?.otherInformation.carBrand}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("companyCarModel")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation.carModel ??
                            corporateModuleDetails?.otherInformation.carModel}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("manufactoreYear")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .manufactureYear ??
                            corporateModuleDetails?.otherInformation
                              .manufactureYear}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("purpose")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation.purpose ??
                            corporateModuleDetails?.otherInformation.purpose}
                        </Text>
                      </Flex>
                    </Flex>
                  ),
                },
              ]}
            />
          )}

          {corporateModuleDetails.type === "property" && (
            <Collapse
              size="large"
              style={{ width: "100%" }}
              items={[
                {
                  key: "4",
                  label: t("propertyInsurance"),
                  children: (
                    <Flex vertical gap={10}>
                      <Flex justify="space-between">
                        <Text>{t("cadastralCode")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .cadastralCode ??
                            corporateModuleDetails?.otherInformation
                              .cadastralCode}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("purpose")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation.purpose ??
                            corporateModuleDetails?.otherInformation.purpose}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("propertyType")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .propertyType ??
                            corporateModuleDetails?.otherInformation
                              .propertyType}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("propertyContents")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .insurePropertyContents
                            ? corporateModuleDetails?.otherInformation
                                .insurePropertyContents === true
                              ? `${t("yes")}`
                              : `${t("no")}`
                            : ""}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("propertyAddress")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation.address ??
                            corporateModuleDetails?.otherInformation.address}
                        </Text>
                      </Flex>
                    </Flex>
                  ),
                },
              ]}
            />
          )}

          {(corporateModuleDetails.type === "property" ||
            corporateModuleDetails.type === "car") && (
            <Collapse
              size="large"
              style={{ width: "100%" }}
              items={[
                {
                  key: "5",
                  label: t("marketValue"),
                  children: (
                    <Flex vertical gap={10}>
                      <Flex justify="space-between">
                        <Text>{t("marketValue")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation
                            .marketValue ??
                            corporateModuleDetails?.otherInformation
                              .marketValue}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("currency")}</Text>
                        <Text>
                          {corporateModuleDetails?.otherInformation.currency ??
                            corporateModuleDetails?.otherInformation.currency}
                        </Text>
                      </Flex>
                    </Flex>
                  ),
                },
              ]}
            />
          )}

          <Collapse
            size="large"
            style={{ width: "100%" }}
            items={[
              {
                key: "6",
                label: t("otherInformation"),
                children: (
                  <Flex vertical gap={10}>
                    <Flex justify="space-between">
                      <Text>{t("insuranceProduct")}</Text>
                      <Text>
                        {corporateModuleDetails?.otherInformation
                          .insuranceProduct ??
                          corporateModuleDetails?.otherInformation
                            .insuranceProduct}
                      </Text>
                    </Flex>

                    <Flex justify="space-between">
                      <Text>{t("comment")}</Text>
                      <Text>
                        {corporateModuleDetails?.comment ??
                          corporateModuleDetails?.comment}
                      </Text>
                    </Flex>
                  </Flex>
                ),
              },
            ]}
          />

          <Collapse
            size="large"
            style={{ width: "100%" }}
            items={[
              {
                key: "7",
                label: t("ContactInformation"),
                children: (
                  <Flex vertical gap={10}>
                    <Flex justify="space-between">
                      <Text>{t("firstName") + "/" + t("lastName")}</Text>
                      <Text>
                        {corporateModuleDetails?.firstname &&
                        corporateModuleDetails?.lastname
                          ? corporateModuleDetails?.firstname +
                            " " +
                            corporateModuleDetails?.lastname
                          : " "}
                      </Text>
                    </Flex>

                    <Flex justify="space-between">
                      <Text>{t("position")}</Text>
                      <Text>
                        {corporateModuleDetails?.position ??
                          corporateModuleDetails?.position}
                      </Text>
                    </Flex>

                    <Flex justify="space-between">
                      <Text>{t("email")}</Text>
                      <Text>
                        {corporateModuleDetails?.email ??
                          corporateModuleDetails?.email}
                      </Text>
                    </Flex>

                    <Flex justify="space-between">
                      <Text>{t("mobile")}</Text>
                      <Text>
                        {corporateModuleDetails?.mobileNumber ??
                          corporateModuleDetails?.mobileNumber}
                      </Text>
                    </Flex>
                  </Flex>
                ),
              },
            ]}
          />
        </Flex>
      )}
    </>
  );
};

export default AdminBusinessModuleDetails;
